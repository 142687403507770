import React from "react";
import { InMemoryDownload } from "../InMemoryDownload";
import { ArtifactBundle } from "../../models";
import { ArtifactFileList } from "./ArtifactFileList";

interface Props {
  bundleFiles: ArtifactFileList;
  bundle: ArtifactBundle;
}

export function Tr3SeFidoAppletFileList({ bundleFiles, bundle }: Props) {
  return (
    <>
      <td>
        {bundleFiles.tr3Scp11cJwtDevKeys && (
          <InMemoryDownload
            displayName="SCP11C Prod Keys (JWT)"
            fileName={`install_fido_fw_script.prod.${bundle.version}.jwt`}
            content={bundleFiles.tr3Scp11cJwtDevKeys?.data}
          />
        )}
      </td>

      <td>
        {bundleFiles.tr3Scp11cJwtDevKeys && (
          <InMemoryDownload
            displayName="SCP11C Dev Keys (JWT)"
            fileName={`install_fido_fw_script.dev.${bundle.version}.jwt`}
            content={bundleFiles.tr3Scp11cJwtDevKeys?.data}
          />
        )}
      </td>

      <td />
    </>
  );
}
